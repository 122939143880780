<template>
  <div class="view__post--wrapper">
    <div class="post__not--found" v-if="postNotFound">
      <PageNotFound
        :description="$t('error.post_not_found.subtitle')"
        :exploreButton="true"
      />
    </div>
    <div v-else class="post__details--content">
      <div class="scrollbar-wrapper" id="scrollbar-wrapper">
        <div class="post__details--inner">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb post-breadcrumb">
              <li
                class="breadcrumb-item back-btn link-btn"
                role="button"
                @click="onBack"
                @keydown.enter="onBack"
                aria-current="Back Button"
                tabindex="0"
              >
                <img
                  :src="require(`@/assets/images/vle/arrow-left.svg`)"
                  alt="Back"
                />
              </li>
              <li
                class="breadcrumb-item link-btn"
                aria-current="All posts"
                @click="navigateTo('AllPosts')"
                @keydown.enter="navigateTo('AllPosts')"
                tabindex="0"
              >
                {{ $t("discussions.post.all_posts") }}
              </li>
              <li
                class="breadcrumb-item link-btn"
                aria-current="Category title"
                :data-title="categoryName"
                @click="navigateTo('Category')"
                @keydown.enter="navigateTo('Category')"
                tabindex="0"
                v-if="categoryTitle"
              >
                {{ categoryName | truncate(12, "...") }}
              </li>
              <li
                class="breadcrumb-item link-btn"
                aria-current="Subcategory title"
                :data-title="subCategoryTitle"
                @click="navigateTo('Category')"
                @keydown.enter="navigateTo('Category')"
                tabindex="0"
                v-if="subCategoryTitle"
              >
                {{ subCategoryTitle | truncate(12, "...") }}
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="Post title"
                :data-title="getSelectedDiscussionPost.title"
              >
                {{ getSelectedDiscussionPost.title | truncate(27, "...") }}
              </li>
            </ol>
          </nav>
          <div class="post-details">
            <div
              class="report-note"
              v-if="getSelectedDiscussionPost?.abuse_flaggers?.length > 0"
            >
              <img :src="reportedImage" alt="report" />
              {{ $t("discussions.post.report_post_note") }}
            </div>
            <div class="post__details--header">
              <h5 class="post-title">
                {{ getSelectedDiscussionPost.title }}
              </h5>
              <div class="header-right">
                <div class="pinned-icon" ref="pin-tooltip">
                  <img
                    :src="pinnedIcon"
                    alt="Pinned"
                    v-if="getSelectedDiscussionPost.pinned"
                  />
                  <b-tooltip
                    :target="() => $refs['pin-tooltip']"
                    triggers="hover focus"
                    placement="bottom"
                  >
                    <div class="cd__tooltip--inner">
                      {{ $t("discussions.post.pinned_tooltip") }}
                    </div>
                  </b-tooltip>
                </div>
                <div class="forums__post--dropdown">
                  <b-dropdown
                    size="lg"
                    right
                    variant="none"
                    toggle-class="text-decoration-none"
                    ref="lxCourseCardDropdown"
                    no-caret
                  >
                    <template #button-content>
                      <ImageButton
                        :defaultIcon="actionIcon"
                        :hoveredIcon="actionHoverIcon"
                        :focusedIcon="actionHoverIcon"
                        :pressedIcon="actionPressedIcon"
                        altText="action"
                        class="default-bg"
                      />
                    </template>
                    <b-dropdown-item
                      class="dropdown-link"
                      v-if="getSelectedDiscussionPost?.ability?.editable"
                      @click="showEditPostForm"
                    >
                      <img :src="editIcon" alt="Edit" />
                      {{ $t("general.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="dropdown-link"
                      v-if="!getIsLearner"
                      @click="togglePinPost"
                    >
                      <img
                        :src="
                          getSelectedDiscussionPost.pinned ? unpinIcon : pinIcon
                        "
                        alt="pin"
                      />
                      {{
                        getSelectedDiscussionPost.pinned
                          ? $t("discussions.post.unpin_post")
                          : $t("discussions.post.pin_post")
                      }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="dropdown-link share-option-container"
                    >
                      <img :src="shareIcon" alt="Share" />
                      {{ $t("course.share") }}
                      <img :src="arrowRightIcon" alt="arrow" class="arrow" />
                      <div class="share-option-wrapper">
                        <ul class="share-option-inner">
                          <li @click="sendEmail">
                            <img :src="emailIcon" alt="email" />
                            {{ $t("discussions.post.email") }}
                          </li>
                          <li @click="copyLink">
                            <img :src="linkIcon" alt="link" />
                            {{ $t("discussions.post.copy_link") }}
                          </li>
                        </ul>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="dropdown-link"
                      variant="warning"
                      v-if="getSelectedDiscussionPost?.ability?.can_report"
                      @click="showReportModal('post')"
                    >
                      <img :src="reportIcon" alt="Report" />
                      {{ $t("general.report") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      class="dropdown-link"
                      variant="danger"
                      v-if="getSelectedDiscussionPost?.ability?.can_delete"
                      @click="showDeletePostModal"
                    >
                      <img :src="deleteIcon" alt="Delete" />
                      {{ $t("general.delete") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div
              class="post__details--body"
              v-html="getSelectedDiscussionPost?.body"
            ></div>
            <template
              v-if="getSelectedDiscussionPost?.content_list?.length > 0"
            >
              <div
                class="post__details--contentlist"
                v-for="(block, index) in getSelectedDiscussionPost.content_list"
                :key="index"
              >
                <div class="post__details--img" v-if="block.image">
                  <img :src="block.image" :alt="block.alt_tag" />
                </div>
                <div
                  class="post__details--body post-details__description"
                  v-if="block.brief_description"
                  v-html="block.brief_description"
                ></div>
              </div>
            </template>
            <div class="post__details--footer">
              <div class="footer-left">
                <div class="post-user">
                  <div
                    class="user-image"
                    :class="{
                      'user-bg':
                        !getSelectedDiscussionPost?.anonymous &&
                        getSelectedDiscussionPost?.profile_image_url !== null
                    }"
                  >
                    <img
                      :src="
                        getSelectedDiscussionPost?.anonymous
                          ? anonymousImage
                          : getSelectedDiscussionPost?.profile_image_url
                          ? getSelectedDiscussionPost?.profile_image_url
                          : defaultAvatar
                      "
                      alt="avatar"
                    />
                  </div>
                  <span>{{
                    getSelectedDiscussionPost?.anonymous
                      ? "Anonymous"
                      : $t("dashboard.welcome_back.user_name", {
                          s: getSelectedDiscussionPost?.username
                        })
                  }}</span>
                </div>
                <div
                  class="post__author--role"
                  v-if="
                    getSelectedDiscussionPost?.author_role &&
                      getSelectedDiscussionPost?.author_role !== 'Learner'
                  "
                >
                  {{ $t("discussions.staff") }}
                </div>
                <div class="post-date">
                  {{
                    isPostEdited
                      ? setTextEdited
                        ? $t("discussions.post.edited_on")
                        : $t("discussions.post.edited")
                      : ""
                  }}
                  {{ getSelectedDiscussionPost?.updated_at | humanizeNew }}
                </div>
              </div>
              <div class="footer-right">
                <UpvoteDownvote
                  :isPostClosed="getSelectedDiscussionPost?.closed"
                  :voteCount="voteCount"
                  :userVoted="userVoted"
                  @toggleLike="toggleVote"
                />
              </div>
            </div>
            <div class="post__foot--note">
              <span class="related">
                {{ $t("discussions.post.related_to") }}&nbsp;
              </span>
              <span>{{ $t("general.who_lxp") }}</span>
            </div>
          </div>
          <div class="post-response">
            <div
              class="post__response--container"
              v-if="!getSelectedDiscussionPost?.closed"
              id="discussion-add-response-form"
            >
              <DiscussionAddResponse
                @reloadResponses="getPostResponse"
                ref="addResponseRef"
              />
            </div>
            <div class="post__response--details">
              <div
                class="post__response--count"
                v-if="getSelectedPostResponses?.count > 0"
              >
                {{ getSelectedPostResponses?.count }}
                {{
                  getSelectedPostResponses?.count > 1
                    ? $t("discussions.new_response.count.x_response_other")
                    : $t("discussions.new_response.count.x_response_one")
                }}
              </div>
              <div
                class="post-closed__note"
                v-if="getSelectedDiscussionPost?.closed"
              >
                <img :src="postClosedIcon" alt="closed post" />
                <div class="post-closed__inner">
                  <h6 class="pc-title">
                    {{ $t("discussions.post.post_details.title") }}
                  </h6>
                  <div class="pc-content">
                    {{ $t("discussions.post.post_details.content") }}
                  </div>
                </div>
              </div>
              <div class="post__response--list">
                <template v-if="getSelectedPostResponses?.count > 0">
                  <ResponseCard
                    :response="response"
                    :isPostClosed="getSelectedDiscussionPost?.closed"
                    v-for="response in getSelectedPostResponses?.results"
                    :key="response.id"
                    @reloadResponses="getPostResponse"
                    @showReportModal="showReportModal"
                  />
                </template>
                <div v-if="isResponsesLoading">
                  <LxpLoader />
                </div>
              </div>
            </div>
            <div
              class="post__response--empty"
              v-if="
                getSelectedPostResponses?.count < 1 &&
                  !getSelectedDiscussionPost?.closed &&
                  !isResponsesLoading
              "
            >
              <img :src="addResponseIcon" alt="Add Response" />
              <div class="post__response--title">
                {{ $t("discussions.new_response.empty.heading") }}
              </div>
              <div class="post__response--content">
                {{ $t("discussions.new_response.empty.content") }}
              </div>
              <LxpButton
                variant="outline-primary"
                @click="scrollToAddResponseForm"
              >
                {{ $t("discussions.new_response.navigation.title") }}
              </LxpButton>
            </div>
          </div>
        </div>
      </div>
      <Modals
        :reportType="reportType"
        :reportId="reportId"
        @delete-post="deletePost"
        @report-post="reportPost"
      />
    </div>
    <Toast
      id="report-toast-creator"
      :imgPath="require('@/assets/images/vle/edit-doc.svg')"
      :title="$t('discussions.new_post.toast.report_creator.title')"
      :description="$t('discussions.new_post.toast.report_creator.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="edit-toast"
      :imgPath="require('@/assets/images/vle/edit-doc.svg')"
      :title="$t('discussions.new_post.toast.edit.title')"
      :description="$t('discussions.new_post.toast.edit.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="copy-link"
      :imgPath="linkCopiedIcon"
      :title="$t('discussions.post.copied_to_clipboard')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="toxic-response-toast"
      :imgPath="require('@/assets/images/vle/toxic-content.svg')"
      :title="$t('discussions.new_post.toast.toxic.response.title')"
      :description="getDescriptionWithLink"
      :autoHide="false"
      :isHTMLContent="true"
      @onHTMLClick="handleHTMLClick"
    ></Toast>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ResponseCard from "./PostDetails/ResponseCard.vue";
import DiscussionAddResponse from "./PostDetails/DiscussionAddResponse.vue";
import getUsernameWithRole from "@/core/mixins/getUsernameWithRole.js";
import UpvoteDownvote from "./PostDetails/UpvoteDownvote.vue";
import discussionViewSelectorMixin from "@/core/mixins/discussionViewSelector.js";
import Modals from "./Modals.vue";
import moment from "moment";
import ctaActionsMixin from "../../CourseDetail/mixins/ctaActions";
import postModalMixin from "../mixins/postModal";
import Toast from "@/components/SelfPaced/Toast.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import openFeedbackForm from "@/core/mixins/openFeedbackForm";
import DISCUSSION_FORUM_MODAL_IDS from "./constants";
import { LxpButton, LxpLoader } from "didactica";
const SCROLL_CONTAINER = "scrollbar-wrapper";
export default {
  mixins: [
    getUsernameWithRole,
    discussionViewSelectorMixin,
    ctaActionsMixin,
    postModalMixin,
    openFeedbackForm
  ],
  components: {
    DiscussionAddResponse,
    ResponseCard,
    UpvoteDownvote,
    Modals,
    Toast,
    PageNotFound,
    LxpButton,
    LxpLoader
  },
  data() {
    return {
      isResponsesLoading: true,
      voteCount: 0,
      userVoted: false,
      categoryTitle: "",
      subCategoryTitle: "",
      defaultAvatar: require("@/assets/images/menu/avatar-grey.svg"),
      editIcon: require("@/assets/images/vle/forums/dropdown/edit.svg"),
      shareIcon: require("@/assets/images/vle/forums/dropdown/share.svg"),
      deleteIcon: require("@/assets/images/vle/forums/dropdown/delete.svg"),
      pinIcon: require("@/assets/images/vle/forums/dropdown/pin-filled.svg"),
      unpinIcon: require("@/assets/images/vle/forums/dropdown/pin.svg"),
      pinnedIcon: require("@/assets/images/vle/forums/pinned.svg"),
      reportIcon: require("@/assets/images/vle/forums/dropdown/report.svg"),
      reportedImage: require("@/assets/images/vle/forums/report.svg"),
      emailIcon: require("@/assets/images/vle/forums/dropdown/email.svg"),
      linkIcon: require("@/assets/images/vle/forums/dropdown/link.svg"),
      linkCopiedIcon: require("@/assets/images/vle/forums/dropdown/link-copied.svg"),
      anonymousImage: require("@/assets/images/vle/anonymous.svg"),
      arrowRightIcon: require("@/assets/images/vle/chevron-right.svg"),
      postClosedIcon: require("@/assets/images/vle/locked-chat.svg"),
      addResponseIcon: require("@/assets/images/vle/forums/response/add-response.svg"),
      actionIcon: require("@/assets/images/vle/forums/response/action.svg"),
      actionHoverIcon: require("@/assets/images/vle/forums/response/action-hover.svg"),
      actionPressedIcon: require("@/assets/images/vle/forums/response/action-pressed.svg"),
      isViewPage: false,
      postNotFound: false
    };
  },
  mounted() {
    this.initializeLocalVariables();
    const scrollContainer = document.getElementById(SCROLL_CONTAINER);
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.amountScrolled);
    }
  },
  computed: {
    ...mapGetters([
      "getSelectedCourse",
      "getSelectedDiscussionPost",
      "getSelectedPostResponses",
      "getIsLearner",
      "language"
    ]),
    author() {
      return this.getUsernameWithRole(
        this.getSelectedDiscussionPost.author,
        this.getSelectedDiscussionPost.author_is_staff
      );
    },
    setTextEdited() {
      return (
        this.$options.filters.humanizeNew(
          this.getSelectedDiscussionPost.updated_at
        ) ===
        moment(this.getSelectedDiscussionPost.updated_at).format("MMM DD, YYYY")
      );
    },
    isPostEdited() {
      const updatedAt = moment(this.getSelectedDiscussionPost.updated_at);
      const createdAt = moment(this.getSelectedDiscussionPost.created_at);
      return updatedAt.isAfter(createdAt);
    },
    getDescriptionWithLink() {
      const htmlText = this.$t(
        "discussions.new_post.toast.toxic.response.feedback_form"
      );
      const descriptionText = this.$t(
        "discussions.new_post.toast.toxic.response.description",
        { s: htmlText }
      );
      const descriptionWithLink = descriptionText.replace(
        `${htmlText}`,
        `<span class="toast-link" id="feedback-link">${htmlText}</span>`
      );
      return descriptionWithLink;
    },
    categoryName() {
      return this.categoryTitle === "course"
        ? this.getSelectedDiscussionPost?.topic_name
        : this.categoryTitle;
    }
  },
  async created() {
    this.$emit("setLoading", true);
    const postId = this.$route.params.postId;
    this.fetchUsersList();

    await this.$store
      .dispatch("getPostDetails", {
        postId
      })
      .then(async () => {
        await this.$store.dispatch("getForumsUserStatus");
        if (
          !this.getSelectedDiscussionPost?.is_update_notified &&
          this.getSelectedDiscussionPost?.id
        ) {
          this.$bvToast.show("edit-toast");
        }
        if (
          !this.getSelectedDiscussionPost?.is_report_notified &&
          this.getSelectedDiscussionPost?.id
        ) {
          this.$bvToast.show("report-toast-creator");
        }
        if (this.getSelectedDiscussionPost.courseware_title) {
          const title = this.getSelectedDiscussionPost.courseware_title;
          const [categoryTitle, subCategoryTitle] = title.split(" / ");
          this.categoryTitle = categoryTitle;
          this.subCategoryTitle = subCategoryTitle;
        } else {
          this.categoryTitle = this.getSelectedDiscussionPost.commentable_id;
          this.subCategoryTitle = "";
        }
        this.$emit("setLoading", false);
      })
      .catch(async err => {
        const status = err.response.status;
        if (status === 403 || status === 401) {
          this.$router.push({ path: `/coursewares/${this.$route.params.id}` });
        } else if (status === 400) {
          //If not enrolled in course
          let err_status = null;
          try {
            await this.$store.dispatch(
              "getDiscussionTopics",
              this.$route.params.id
            );
          } catch (err) {
            err_status = err.response.status;
          }
          if (err_status === 404) {
            this.$router.push({
              path: `/coursewares/${this.$route.params.id}`
            });
          }
          //If post is deleted but enrolled in course
          else {
            await this.$store.dispatch("getForumsUserStatus");
            this.$emit("setLoading", false);
            this.postNotFound = true;
          }
        }
      });
    this.$store.dispatch("getDiscussionTopics", this.$route.params.id).then();
    await this.getPostResponse();
  },
  watch: {
    getSelectedDiscussionPost() {
      this.initializeLocalVariables();
      this.getPostResponse();
    }
  },
  beforeDestroy() {
    const scrollContainer = document.getElementById(SCROLL_CONTAINER);
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.amountScrolled);
    }
  },
  methods: {
    amountScrolled() {
      let responseListWrapper = document.getElementById(SCROLL_CONTAINER),
        documentHeight =
          responseListWrapper.scrollHeight - responseListWrapper.clientHeight,
        scrollTopPosition = responseListWrapper.scrollTop,
        scrolledPercentage = Math.floor(
          (scrollTopPosition / documentHeight) * 100
        );
      if (
        scrolledPercentage >= 90 &&
        !this.isResponsesLoading &&
        this.getSelectedPostResponses.next_page !== null
      ) {
        this.isResponsesLoading = true;
        this.loadMoreResponses();
      }
    },
    initializeLocalVariables() {
      this.voteCount = this.getSelectedDiscussionPost?.votes?.count;
      this.userVoted = this.getSelectedDiscussionPost?.voted;
    },
    async toggleVote() {
      return new Promise(resolve => {
        let obj = {
          courseId: this.$route.params.id,
          postId: this.getSelectedDiscussionPost.id
        };
        if (this.userVoted) {
          this.$store.dispatch("deletePostVote", obj).then(voteCount => {
            this.voteCount = voteCount;
            this.userVoted = false;
            resolve();
          });
        } else {
          this.$store.dispatch("createPostVote", obj).then(voteCount => {
            this.voteCount = voteCount;
            this.userVoted = true;
            resolve();
          });
        }
      });
    },
    getPostDetails() {
      this.$store
        .dispatch("getPostDetails", {
          postId: this.getSelectedDiscussionPost.id
        })
        .then(() => {
          this.$store.commit(
            "SET_DISCUSSION_VIEW_SELECTOR",
            this.DISCUSSION_VIEW_SELECTOR.PostDetails
          );
        });
    },
    loadMoreResponses() {
      this.getPostResponse({
        postId: this.getSelectedDiscussionPost.id,
        page: this.getSelectedPostResponses.next_page
      });
    },
    getPostResponse(
      params = {
        postId: this.getSelectedDiscussionPost.id,
        page: 1
      }
    ) {
      if (this.getSelectedDiscussionPost.comments_count > 0) {
        this.isResponsesLoading = true;
        this.$store.dispatch("getPostResponses", params).then(() => {
          this.isResponsesLoading = false;
        });
      } else {
        this.isResponsesLoading = false;
        this.$store.commit("RESET_POST_RESPONSES");
      }
    },
    scrollToAddResponseForm() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
      document.getElementById("discussion-add-response-form").scrollIntoView({
        behavior: "smooth"
      });
      this.$refs.addResponseRef.editor.focus();
    },
    onBack() {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.PostsList
      );
      this.$router
        .push({
          path: `/self-paced/${this.$route.params.id}/forums/${this.getSelectedDiscussionPost?.commentable_id}/view`
        })
        .catch(err => err);
    },
    showEditPostForm() {
      this.$emit("from-view-post", true);
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.AddPostForm
      );
      this.$router.push({
        path: `/self-paced/${this.$route.params.id}/forums/${this.$route.params.postId}/edit`
      });
    },
    deletePost() {
      this.$store
        .dispatch("deleteCourseDiscussionPost", {
          postId: this.getSelectedDiscussionPost.id
        })
        .then(() => {
          this.$store.commit("SET_ALL_POSTS_ACTIVE");
          this.$store.commit(
            "SET_DISCUSSION_VIEW_SELECTOR",
            this.DISCUSSION_VIEW_SELECTOR.PostsList
          );
          this.$bvToast.show("delete-toast");
          this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
          this.$router
            .push({
              path: `/self-paced/${this.$route.params.id}/forums/${this.getSelectedDiscussionPost?.commentable_id}/view`
            })
            .catch(err => err);
        });
    },
    reportPost(reportData) {
      let dispatchAction = "";
      if (reportData.reportType === "response") {
        dispatchAction = "reportPostResponse";
      } else {
        dispatchAction = "reportCourseDiscussionPost";
      }
      this.$store
        .dispatch(dispatchAction, {
          courseId: this.$route.params.id,
          reportId:
            reportData.reportType === "response"
              ? reportData.reportId
              : this.getSelectedDiscussionPost.id,
          reportOption: reportData.reportData
        })
        .then(() => {
          if (reportData.reportType === "response") {
            this.$bvModal.hide(DISCUSSION_FORUM_MODAL_IDS.REPORT_POST_MODAL);
            const params = {
              postId: this.getSelectedDiscussionPost.id,
              page: 1
            };
            this.$store.dispatch("getPostResponses", params);
            this.$emit("report-post-success");
            this.$bvToast.show("report-response-toast");
          } else {
            this.$store.commit(
              "SET_DISCUSSION_VIEW_SELECTOR",
              this.DISCUSSION_VIEW_SELECTOR.PostsList
            );
            this.$router.push({
              path: `/self-paced/${this.$route.params.id}/forums/${this.getSelectedDiscussionPost?.commentable_id}/view`
            });
            this.$bvToast.show("report-toast");
            this.$store.dispatch("getDiscussionTopics", this.$route.params.id);
          }
        });
    },
    togglePinPost() {
      let dispatchAction = "";
      if (this.getSelectedDiscussionPost.pinned) {
        dispatchAction = "unpinCourseDiscussionPost";
      } else {
        dispatchAction = "pinCourseDiscussionPost";
      }
      let obj = {
        courseId: this.$route.params.id,
        postId: this.getSelectedDiscussionPost.id
      };
      this.$store.dispatch(dispatchAction, obj).then(() => {
        this.$store
          .dispatch("getPostDetails", {
            postId: this.getSelectedDiscussionPost.id
          })
          .then();
      });
    },
    copyLink() {
      this.copyUrl();
      this.$bvToast.show("copy-link");
    },
    sendEmail() {
      const postUrl = `https://${window.location.host}` + this.$route.fullPath;
      const courseTitle = this.getSelectedCourse.title;
      const subject = this.$t("discussions.post.share_email.subject");
      const body = `
      ${this.$t("discussions.post.share_email.greeting")}\n\n
      ${this.$t("discussions.post.share_email.intro", { s: courseTitle })}\n\n
      ${this.$t("discussions.post.share_email.steps.step1")}\n
      ${this.$t("discussions.post.share_email.steps.step2")}\n
      ${this.$t("discussions.post.share_email.steps.step3", {
        s: this.getSelectedDiscussionPost.title
      })}\n\n
      ${postUrl}
      `;
      const mailtoLink = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    },
    fetchUsersList() {
      this.$store.dispatch("getCourseUsersList", {
        course_id: this.$route.params.id
      });
    },
    handleHTMLClick(e) {
      if (e.target.id === "feedback-link") {
        this.openFeedbackForm();
      }
    },
    navigateTo(destination) {
      const basePath = `/self-paced/${this.$route.params.id}/forums`;
      const topicPath = `${basePath}/${this.getSelectedDiscussionPost?.commentable_id}/view`;
      const routePath = destination === "AllPosts" ? basePath : topicPath;
      this.$router.push({ path: routePath });
    }
  },
  destroyed() {
    // When the component destroyed reset the store values
    this.$store.commit("RESET_POST_RESPONSES");
  }
};
</script>
<style lang="scss" scoped>
.view__post--wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 54px;
  left: 0;
  width: 100%;
  height: calc(100% - 54px);
  background: $brand-primary-50;
  z-index: 2;
  .post__details--content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 28px 24px 0;
    min-height: 0;
    .scrollbar-wrapper {
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        border: none;
        background-color: $brand-neutral-75;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: none;
        background-color: $brand-neutral-300;
      }
    }
    .post__details--inner {
      width: 608px;
      margin: 0px auto 28px;
      display: flex;
      flex-direction: column;
    }
  }
  .post-breadcrumb {
    padding: 0;
    background-color: transparent;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 32px;
    .breadcrumb-item {
      @include body-medium;
      color: $brand-neutral-700;
      position: relative;
      &:first-letter {
        text-transform: capitalize;
      }
      &:before {
        display: none;
      }
      &:not(:first-child):not(:last-child) {
        &:after {
          content: "";
          background-repeat: no-repeat;
          background-size: 56%;
          background-image: url("~@/assets/images/vle/chevron-right.svg");
          display: block;
          position: absolute;
          right: 4px;
          background-position: center;
          top: 50%;
          width: 10px;
          height: 10px;
          transform: translateY(-50%);
        }
      }
      &:not(.back-btn) {
        padding: 2px 20px 2px 4px;
        &:last-child {
          padding-right: 4px;
        }
        &:not(:last-child) {
          border: 1px solid transparent;
          &:hover,
          &:focus {
            border-radius: 8px;
            color: $brand-primary-400;
            background-color: $brand-primary-75;
            &:after {
              filter: invert(46%) sepia(100%) saturate(3171%) hue-rotate(193deg)
                brightness(99%) contrast(99%);
            }
          }
          &:focus {
            outline: none;
            border-color: $brand-primary-400;
          }
          &:active {
            color: $brand-primary-600;
          }
        }
      }
      &.back-btn {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        @include flex-horizontal-center;
        background-color: $brand-neutral-50;
        margin-right: 4px;
        &:focus {
          outline: none;
          border: 1px solid $brand-neutral-700;
        }
      }
      &.active {
        letter-spacing: 0.1px;
        font-weight: 500;
        color: $brand-primary;
      }
      &.link-btn {
        cursor: pointer;
      }
      &[data-title]:hover:before {
        display: block;
        content: attr(data-title);
        position: absolute;
        top: calc(100% + 12px);
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        padding: 4px 8px;
        @include body-medium;
        color: $brand-primary-50;
        background-color: $brand-primary;
        width: max-content;
        max-width: 220px;
        word-break: break-word;
        z-index: 1;
      }
    }
  }
  .report-note {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    background-color: $brand-warning-100;
    margin-bottom: 24px;
    @include label-large($brand-neutral-700, 500);
    img {
      margin-right: 8px;
    }
  }
  .post-details {
    border-radius: 16px;
    padding: 16px 24px;
    background-color: $brand-neutral-0;
    .post__details--header {
      margin-bottom: 24px;
      @include horizontal-space-between;
      .post-title {
        @include h5($brand-neutral-900);
        margin: 0;
        word-break: break-word;
      }
      .header-right {
        display: flex;
        align-items: center;
        .pinned-icon {
          margin-right: 12px;
        }
      }
    }
    .post__details--body {
      @include subtitle-regular;
      font-weight: 400;
      color: $brand-neutral-700;
      word-break: break-word;
      margin-bottom: 24px;
    }
    .post__details--img {
      margin-bottom: 24px;
      border-radius: 8px;
      overflow: hidden;
      height: auto;
      background-color: $brand-neutral-0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .post__details--footer {
      @include horizontal-space-between;
      .footer-left {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .footer-right {
        display: flex;
        align-items: center;
      }
    }
    .post-user {
      display: flex;
      align-items: center;
      max-width: 50%;
      .user-image {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        @include flex-horizontal-center;
        &.user-bg {
          width: 32px;
          min-width: 32px;
          height: 32px;
          border: 2px solid $brand-primary-400;
          img {
            height: 100%;
            object-fit: cover;
          }
        }

        img {
          width: 100%;
        }
      }

      span {
        margin-left: 8px;
        @include label-large($brand-neutral-700, 500);
      }
    }
    .post__author--role {
      @include label-large($brand-secondary1-400, 500);
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid $brand-secondary1-400;
      background-color: $brand-secondary1-50;
      margin-left: 8px;
    }
    .post-date {
      @include body-medium;
      font-weight: 500;
      letter-spacing: 0.1px;
      color: $brand-neutral-700;
      position: relative;
      padding-left: 10px;
      margin-left: 10px;
      &:before {
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $brand-neutral-700;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .post__foot--note {
      @include body-medium;
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid $brand-neutral-200;
      .related {
        color: $brand-neutral-300;
      }
      &:not(.related) {
        color: $brand-primary-400;
      }
    }
  }
  .share-option-container {
    position: relative;
    &:hover {
      .share-option-wrapper {
        display: block;
      }
    }
    .share-option-wrapper {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      padding-left: 8px;
    }
    .share-option-inner {
      width: 114px;
      border-radius: 8px;
      background-color: $brand-neutral-0;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      list-style-type: none;
      padding: 4px;
      overflow: hidden;
      li {
        padding: 8px;
        display: flex;
        align-items: center;
        @include label-large($brand-neutral-700, 400);
        border-radius: 4px;
        &:hover {
          background-color: $brand-primary-100;
        }
      }
    }
  }
  .post__not--found {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .post-response {
    .post__response--details {
      .post__response--count {
        margin-top: 32px;
        @include label-large;
      }
    }
    .post-closed__note {
      display: flex;
      align-items: flex-start;
      background-color: $brand-primary-100;
      border-radius: 16px;
      padding: 16px;
      margin-top: 32px;
      .post-closed__inner {
        margin-left: 8px;
      }
      .pc-title {
        letter-spacing: 0.15px;
        color: $brand-neutral-700;
        line-height: 20px;
      }
      .pc-content {
        margin-top: 4px;
        @include body-medium;
        color: $brand-neutral-700;
      }
    }
    .post__response--list {
      margin-top: 32px;
    }
    .post__response--empty {
      @include center;
      padding: 16px 16px 24px;
      border-radius: 8px;
      background-color: $brand-neutral-0;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      width: 100%;
      margin-top: 32px;
      .post__response--title {
        margin-top: 24px;
        @include subtitle-large($brand-neutral-900);
        letter-spacing: 0.055px;
      }
      .post__response--content {
        margin-top: 8px;
        color: $brand-neutral-700;
      }
      .lxp-button {
        margin-top: 24px;
        border-width: 1px;
        height: 36px;
        width: 193px;
        &:hover,
        &:focus {
          color: $brand-primary-400;
          border-color: $brand-primary-400;
          background-color: $brand-primary-100;
        }
        &:active {
          color: $brand-primary-600;
          border-color: $brand-primary-600;
          background-color: transparent;
        }
      }
    }
  }
  .post__not--found {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .post__not--found {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

[dir="rtl"] {
  .view__post--wrapper {
    .post-breadcrumb {
      .breadcrumb-item {
        &:not(.back-btn) {
          padding-left: 8px;
          padding-right: 16px;
        }
        &:not(:first-child):not(:nth-child(2)):before {
          left: auto;
          right: 0;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .share-option-container {
      .share-option-wrapper {
        left: auto;
        right: 100%;
        padding-left: 0px;
        padding-right: 8px;
      }
    }
    .post-details {
      .post-user {
        span {
          margin-left: 0px;
          margin-right: 8px;
        }
      }
      .post-date {
        padding-left: 0px;
        padding-right: 10px;
        margin-left: 0px;
        margin-right: 10px;
        &:before {
          left: auto;
          right: 0;
        }
      }
    }
  }
  .lx-course-card-dropdown {
    .dropdown-menu {
      text-align: right;
    }
  }
}
</style>
